<template>
  <div class="team-report">
    <div class="header">
      <div class="header-top flex align-center">
        <img src="@/assets/img/user/teamReport/help.png" alt="" />
        <p class="text">数据有差异？</p>
        <p class="time">展示近30天收入</p>
      </div>
      <div class="tab-hold">
        <van-tabs v-model:active="active" @click="handleChange">
          <van-tab>
            <template #title>
              <span>收入高至低</span>
              <img
                v-show="active === 0 && sortMethod == 1"
                src="@/assets/img/user/teamReport/sort-red.png"
                alt=""
              />
              <img
                v-show="active === 0 && sortMethod == 2"
                src="@/assets/img/user/teamReport/top.png"
                alt=""
              />
              <img
                v-show="active === 1"
                src="@/assets/img/user/teamReport/sort-grey.png"
                alt=""
              />
            </template>
          </van-tab>
          <van-tab>
            <template #title>
              <span>活跃时间</span>
              <img
                v-show="active === 0"
                src="@/assets/img/user/teamReport/sort-grey.png"
                alt=""
              />
              <img
                v-show="active === 1 && sortMethod == 3"
                src="@/assets/img/user/teamReport/sort-red.png"
                alt=""
              />
              <img
                v-show="active === 1 && sortMethod == 4"
                src="@/assets/img/user/teamReport/top.png"
                alt=""
              />
            </template>
          </van-tab>
        </van-tabs>
      </div>
    </div>
    <div class="content">
      <div class="tab-ul">
        <van-list v-model:loading="loading" :finished="finished" @load="onLoad">
          <template v-if="list.length > 0">
            <div v-for="(item, index) in list" :key="index" class="tab-li">
              <div class="li-top flex align-center">
                <img :src="item.picture" alt="" />
                <p class="name">{{ item.name }}</p>
                <p class="time">上次活跃：{{ item.last_trade_time }}</p>
              </div>
              <div class="li-bottom flex flex-between">
                <div class="l-b-l">
                  <p class="money">¥{{ item.amount }}</p>
                  <p class="text">预估累计返佣收益</p>
                </div>
                <div class="l-b-r">
                  <p class="num">{{ item.goodNum }}</p>
                  <p class="text">累计购买商品数量</p>
                </div>
              </div>
            </div>
          </template>
          <template v-else-if="finished">
            <div class="no-list">
              <img src="@/assets/img/user/teamReport/no-data.png" alt="" />
              <p class="no-text">暂无内容</p>
            </div>
          </template>
        </van-list>
      </div>
    </div>
  </div>
</template>
<script src="./index.js"></script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
<style lang="scss">
.team-report {
  .tab-hold {
    .van-tabs__wrap {
      height: auto !important;
    }
    .van-tabs__nav {
      padding-top: 15px !important;
      padding-bottom: 15px !important;
    }
    .van-tabs__line {
      width: 15px !important;
      height: 2px !important;
      background: #e62129 !important;
      bottom: 0 !important;
    }
    .van-tab {
      height: 21px !important;
      font-size: 15px !important;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333 !important;
      line-height: 21px !important;
      padding: 0 !important;
      flex: unset !important;
      .van-tab__text {
        span {
          margin-bottom: 15px;
        }
        img {
          width: 6px;
          height: 10px;
          margin-top: 6px;
        }
      }
    }
    .van-tab:nth-child(1) {
      width: auto !important;
      margin: 0 0 0 15px !important;
      .van-tab__text {
        span {
          margin-right: 3px;
        }
      }
    }
    .van-tab:nth-child(2) {
      width: auto !important;
      margin: 0 0 0 25px !important;
      .van-tab__text {
        span {
          margin-right: 5px;
        }
      }
    }
    .van-tab--active {
      color: #e62129 !important;
    }
  }
  .content {
    height: calc(100% - 110px);
    padding-top: 110px;
    .van-list {
      height: 100%;
      overflow-y: scroll;
    }
    .van-list__loading {
      background: #f4f6f7 !important;
    }
    .tab-ul {
      width: 345px;
      margin: 0 auto 0;
      height: 100%;
      .no-list {
        height: 564px;
        background: #ffffff;
        border-radius: 10px;
        text-align: center;
        img {
          width: 98px;
          height: 98px;
          margin: 135px auto 25px;
        }
        p {
          height: 22px;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #cccccc;
          line-height: 22px;
          text-align: center;
        }
      }
      .tab-li {
        height: 126px;
        background: #ffffff;
        border-radius: 10px;
        margin-bottom: 10px;
        .li-top {
          height: 24px;
          padding: 15px 0 10px 0;
          border-bottom: solid 1px #ededed;
          img {
            width: 24px;
            height: 24px;
            margin: 0 7px 0 15px;
          }
          .name {
            flex-grow: 1;
            text-align: left;
            height: 20px;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            line-height: 20px;
          }
          .time {
            height: 20px;
            font-size: 11px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            line-height: 20px;
            margin-right: 15px;
          }
        }
        .li-bottom {
          margin: 15px 27px 0 31px;
          text-align: center;
          .money,
          .num {
            height: 30px;
            font-size: 18px;
            font-family: Antonio-Bold, Antonio;
            font-weight: bold;
            color: #e62129;
            line-height: 27px;
            margin-bottom: 2px;
          }
          .text {
            height: 14px;
            font-size: 10px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #8c8f90;
            line-height: 14px;
          }
          .l-b-l {
            width: 124px;
          }
          .l-b-r {
            width: 130px;
            .num {
              color: #231815;
            }
          }
        }
      }
    }
  }
}
</style>
import { groupReport } from "@/utils/user.js"
export default {
    name: "MyFans",
    data() {
        return {
            active: 0,
            loading: false,
            finished: false,
            sortMethod: 1,
            pageNo: 1,
            pageSize: 10,
            list: []
        }
    },
    methods: {
        handleChange(val) {
            if (val === 0) {
              this.sortMethod = this.sortMethod === 1 ? 2 : 1
            } else {
              this.sortMethod = this.sortMethod === 3 ? 4 : 3
            }
            this.pageNo = 1
            this.loading =  true
            this.finished =  false
            this.list =  []
            this.getTeamList()
        },
        onLoad() {
            this.loading = true
            // 异步更新数据
            setTimeout(() => {
                this.getTeamList();
            }, 300);
        },
        getTeamList() {
            let data = {
                // userId: this.$cookies.get('userId'),
                memberId: this.$cookies.get('memberId'),
                pageNo: this.pageNo,
                pageSize: this.pageSize,
                sortMethod: this.sortMethod
            }
            groupReport(data).then(res => {
                this.loading = false;
                if (res && res.code == "0") {
                    const arr = res && res.data && res.data.dataList ? res.data.dataList :  []
                    this.list = this.list.concat(arr)
                    if (arr.length < this.pageSize) {
                        this.finished = true;
                    } else {
                        this.pageNo++;
                    }
                } else {
                    this.finished = true;
                }
              })
        },
        showFun() {
            this.isShow = true
        }
    }
}